
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ADD_ENDPOINT, EDIT_ENDPOINT, GET_PLATFORM_CONFIG } from '@/store/types';
import { API, emptyEndpoint, Endpoint } from '@/store/apis/models';
import { namespaces } from '@/scripts/namespaces';
import { API_PATH_REGEX, modes } from '@/scripts/shared';
import RequestResponseBody from '@/components/apis/endpoints/RequestResponseBody.vue';
import { SchemaType } from '@/scripts/shareModels/schema';
import { generateParentChainForSchema } from '@/components/editor/portMapping/scripts';

@Component({
  components: {
    RequestResponseBody,
  },
})
export default class AddOrEditEndpoint extends Vue {
  // Props
  @Prop() api!: API;

  @Prop() mode!: any;

  @Prop({ default: undefined }) endpoint!: Endpoint | undefined;

  // Getters
  @Getter(GET_PLATFORM_CONFIG, { namespace: namespaces.PLATFORM_CONFIG }) platformConfig: any;

  // Actions
  @Action(ADD_ENDPOINT, { namespace: namespaces.API }) addEndpoint: any;

  @Action(EDIT_ENDPOINT, { namespace: namespaces.API }) editEndpoint: any;

  // Data

  private dialog = false;

  private valid = false;

  private loading = false;

  private workspaceId: string = this.$route.params.workspaceId;

  private path = '';

  private pathRules: any = {
    required: (v: string) => !!v || this.$t('required_field'),
    unique: (v: string) => this.validatePath(v),
    textRule: (v: string) => API_PATH_REGEX.test(v) || this.$t('invalid_url'),
  };

  private requestTypes = ['GET', 'POST', 'PUT'];

  private selectedType = '';

  private modes = modes;

  // Computed

  /**
   * Gets the whole URL where the endpoint can be
   * called on
   */
  get pathPrefix(): string {
    return `${this.platformConfig.apiBasePath}${this.api.path}/`;
  }

  // Watchers

  @Watch('dialog')
  onDialogChanged() {
    // when the dialog is opened
    // for editing an endpoint
    if (
      this.dialog
      && this.mode === modes.EDIT
      && this.endpoint
      && this.endpoint.apiId === this.api.id
    ) {
      // setup the v-models
      this.path = this.endpoint.request.path;

      this.selectedType = this.endpoint.request.method;
    }
  }

  // Methods

  /**
   * Adds the endpoint to the API after
   * validating the whole form
   */
  async addOrEditEndpoint() {
    const { form, request, response }: any = this.$refs;

    form.validate();

    if (this.api.id && this.valid) {
      this.loading = true;

      const { payload: requestBody }: { payload: SchemaType } = request;
      const { payload: responseBody }: { payload: SchemaType } = response;

      // generate parent chain for the bodies
      generateParentChainForSchema(requestBody, []);
      generateParentChainForSchema(responseBody, []);

      // initialize the endpoint object
      const endpoint: Endpoint = {
        apiId: this.api.id,
        flows: this.endpoint ? this.endpoint.flows : [],

        request: {
          method: this.selectedType,
          path: this.path,
          payload: requestBody,
        },

        response: {
          status: 200,
          payload: responseBody,
        },
      };

      if (this.mode === modes.EDIT) {
        this.$set(endpoint, 'id', this.endpoint?.id);

        await this.editEndpoint({
          workspaceId: this.workspaceId,
          apiId: this.api.id,
          endpoint,
        });
      } else if (this.mode === modes.ADD) {
        await this.addEndpoint({
          workspaceId: this.workspaceId,
          apiId: this.api.id,
          endpoint,
        });
      }

      this.loading = false;
      this.dialog = false;
    }
  }

  /**
   * Determines if the path being used
   * for the endpoint is unique
   *
   * @param path - The path being used for the
   * endpoint
   *
   * @returns - Translation String if path exists
   * OR true if path name is ok to use
   */
  validatePath(path: string) {
    if (this.api.endpoints) {
      const { endpoints } = this.api;

      // find all endpoints in the api that match
      // the path name currently the user has typed in
      const foundEndpoints = endpoints.filter(
        (e: Endpoint) => e.request.path.toLowerCase() === path.trim().toLowerCase()
          && (this.mode === this.modes.EDIT ? e.id !== this.endpoint?.id : true),
      );

      // check if the current path name is used
      // with the exact same method (GET/POST etc)
      if (
        foundEndpoints
        && foundEndpoints
          .map((e) => e.request.method.toLowerCase())
          .includes(this.selectedType.toLowerCase())
      ) {
        return this.$t('unique_path');
      }
    }

    return true;
  }
}
