
import { Component, Prop, Vue } from 'vue-property-decorator';
import AddAPI from '@/components/apis/AddAPI.vue';
import DeleteAPI from '@/components/apis/DeleteAPI.vue';
import AddOrEditEndpoint from '@/components/apis/endpoints/AddOrEditEndpoint.vue';
import DeleteEndpoint from '@/components/apis/endpoints/DeleteEndpoint.vue';
import { SchemaType } from '@/scripts/shareModels/schema';
import { modes } from '@/scripts/shared';
import { buildJSONForDisplay } from '../editor/portMapping/scripts';

@Component({
  components: {
    AddAPI,
    DeleteAPI,
    AddOrEditEndpoint,
    DeleteEndpoint,
  },
})
export default class SingleAPI extends Vue {
  // Props
  @Prop() api: any;

  // Data
  private search = '';

  private editDialog = false;

  private headers = [
    { text: 'Type', value: 'request.method' },
    { text: 'Path', value: 'request.path', align: 'center' },
    { text: 'Status', value: 'response.status', align: 'center' },
    { text: 'Request Body', value: 'request.payload' },
    { text: 'Response Body', value: 'response.payload' },
    { text: 'Flows using the Endpoint', value: 'flows', align: 'center' },
    { text: 'Actions', value: 'actions' },
  ];

  private selectedItem: SchemaType = {
    name: '',
    type: '',
  };

  private showPayload = false;

  private modes = modes;

  private workspaceId: string = this.$route.params.workspaceId;

  // Methods

  /**
   * Uses the payload of an endpoint and
   * forms a JSON to be displayed on the
   * UI for the user
   *
   * @param payload - The payload of the endpoint
   * that needs to be beautified
   */
  beautifyPayload = (payload: SchemaType) => {
    if (payload.type === 'string') return 'string';

    let json: any = payload.type === 'object' ? {} : [];

    if (payload.id && payload.elements) {
      // if payload has elements, go through them
      // and form the JSON
      payload.elements.forEach((element) => {
        json = buildJSONForDisplay(element, json);
      });
    } else {
      // just handle the payload itself as
      // it has no elements
      json = buildJSONForDisplay(payload, json);
    }

    json = JSON.stringify(json, undefined, 2);

    return json;
  };

  /**
   * Opens dialog to show full JSON
   * schema of an endpoint
   */
  expandJSON(item: SchemaType) {
    this.selectedItem = item;
    this.showPayload = true;
  }

  /**
   * Determins if a schema is of type string
   */
  isString = (item: SchemaType) => item.type === 'string';

  getMaxWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case 'sm':
        return '2rem';

      case 'md':
        return '5rem';

      case 'lg':
        return '6rem';

      default:
        return '12rem';
    }
  }

  redirectToFlow(flow: string) {
    this.$router.push({ path: `/editor/${this.workspaceId}/${flow}` });
  }
}
