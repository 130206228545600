
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import SingleAPI from '@/components/apis/SingleAPI.vue';
import { FETCH_APIS, GET_APIS } from '@/store/types';
import { API } from '@/store/apis/models';
import AddAPI from '@/components/apis/AddAPI.vue';
import { namespaces } from '@/scripts/namespaces';

@Component({
  components: {
    SingleAPI,
    AddAPI,
  },
})
export default class APIList extends Vue {
  // Getters
  @Getter(GET_APIS, { namespace: namespaces.API }) apis: any;

  // Actions
  @Action(FETCH_APIS, { namespace: namespaces.API }) fetchApis: any;

  // Data
  private apiSearchTerm: string = '';

  private workspaceId: string = this.$route.params.workspaceId;

  private addDialog: boolean = false;

  // Computed
  get filteredApis() {
    // return all packages if search term is empty
    if (!this.apiSearchTerm) {
      return this.apis;
    }

    // return filtered packages
    return this.apis.filter(
      (a: API) => a.name.toLowerCase().includes(this.apiSearchTerm.toLowerCase()),
    );
  }

  // Vue Life Cycle Hooks
  async mounted() {
    await this.fetchApis(this.workspaceId);
  }
}
