
import { findKeyInNestedObject } from '@/components/editor/portMapping/scripts';
import { MESSAGE_TYPE_REGEX, TYPE_ID_PREFIX } from '@/scripts/shared';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SchemaType } from '@/scripts/shareModels/schema';
import { AtomicTypes } from '@/scripts/shareModels/types';
import * as nanoid from 'nanoid';
import { Endpoint } from '@/store/apis/models';

@Component
export default class RequestResponseBody extends Vue {
  @Prop() title!: string;

  @Prop({ default: undefined }) endpoint!: Endpoint | undefined;

  @Prop({ default: '' }) type!: string;

  @Prop({ default: false }) disableEditing!: boolean;

  // Data
  private BASE_TYPES = {
    LIST: 'list',
    OBJECT: 'object',
    STRING: 'string',
  };

  // list of objects in the treeview that are expanded
  private openedObjects: string[] = [];

  private keyRules: any = {
    required: (v: string) => !!v || this.$t('required_field'),
    namingRule: (v: string) => MESSAGE_TYPE_REGEX.test(v) || this.$t('message_type_invalid'),
    unique: (item: SchemaType) => this.validateKey(item),
  };

  // base type for the request/response body
  private baseTypes = [this.BASE_TYPES.LIST, this.BASE_TYPES.OBJECT, this.BASE_TYPES.STRING];

  private selectedBaseType = '';

  private payload: SchemaType = {
    name: 'payload',
    type: '',
    elements: [],
  };

  // possible types for body with base type object
  private objectTypes = AtomicTypes.concat([this.BASE_TYPES.OBJECT]);

  // possible types for body with base type list
  private listTypes = AtomicTypes;

  private selectedListType = '';

  // Computed
  /**
   * Determines if the user wants the body
   * as an Object
   */
  get isObject(): boolean {
    return this.payload.type === this.BASE_TYPES.OBJECT;
  }

  /**
   * Determines if the user wants the body
   * as a List
   */
  get isList(): boolean {
    return this.payload.type === this.BASE_TYPES.LIST;
  }

  /**
   * Returns an appropriate icon for the
   * BASE TYPE of the request/response body
   */
  get prependIcon(): string {
    switch (this.selectedBaseType) {
      case this.BASE_TYPES.LIST:
        return 'mdi-code-brackets';

      case this.BASE_TYPES.OBJECT:
        return 'mdi-code-braces';

      default:
        return 'mdi-format-quote-close';
    }
  }

  // Vue Life Cycle Hooks
  mounted() {
    // if the dialog was opened
    // to edit the endpoint
    if (this.endpoint) {
      // setting up the v-models

      let payload: SchemaType;
      if (this.type === 'request') {
        payload = this.endpoint.request.payload;
      } else {
        payload = this.endpoint.response.payload;
      }

      this.payload = $.extend(true, {}, payload);
      this.selectedBaseType = payload.type;

      if (this.payload.type === this.BASE_TYPES.LIST) {
        this.selectedListType = this.payload.list_type ? this.payload.list_type : '';
      }
    }
  }

  // Methods

  /**
   * Adds a new key to the
   * request/response body
   */
  addNewKey() {
    if (!this.payload.elements) this.payload.elements = [];

    this.payload.elements.push({
      id: `${TYPE_ID_PREFIX}${nanoid.nanoid()}`,
      name: '',
      type: '',
    });
  }

  /**
   * Removes a given key from the
   * request/respone body
   *
   * @param item - the item to remove
   */
  removeKey(item: SchemaType) {
    if (item.id) {
      // recursively find key in payload
      const result = findKeyInNestedObject(this.payload, item.id);

      if (result) {
        const { parent, indexOfChild } = result;

        // remove it
        if (parent && parent.elements) parent.elements.splice(indexOfChild, 1);
      }
    }
  }

  /**
   * When the user selects the base type for
   * the request/response body, the body's type
   * is set accordingly
   */
  initializePayload() {
    switch (this.selectedBaseType) {
      case this.BASE_TYPES.LIST:
        this.payload.type = this.BASE_TYPES.LIST;
        break;

      case this.BASE_TYPES.OBJECT:
        this.payload.type = this.BASE_TYPES.OBJECT;

        // initiate elements array if it doesnt exist
        if (!this.payload.elements) this.payload.elements = [];

        // push a new element only if there's no element inside
        if (this.payload.elements) {
          this.payload.elements.push({
            id: `${TYPE_ID_PREFIX}${nanoid.nanoid()}`,
            name: '',
            type: '',
          });
        }

        break;

      default:
        this.payload.type = this.BASE_TYPES.STRING;
    }
  }

  /**
   * Adds a new element in the given
   * schema
   *
   * @param schema - the schema to add an
   * element to
   */
  appendElements(schema: SchemaType) {
    if (schema.type === this.BASE_TYPES.OBJECT) {
      if (!schema.elements) {
        this.$set(schema, 'elements', []);
      }

      if (schema.elements) {
        schema.elements.push({
          id: `${TYPE_ID_PREFIX}${nanoid.nanoid()}`,
          name: '',
          type: '',
        });
      }

      if (schema.id) this.openedObjects.push(schema.id);
    }
  }

  /**
   * Finds out if the key being typed in
   * is unique within the elements of the
   * direct parent of the @param item
   *
   * @param item - The schema that needs to
   * be validated
   */
  validateKey(item: SchemaType) {
    if (item.id) {
      // recursively find key in payload
      const result = findKeyInNestedObject(this.payload, item.id);

      if (result) {
        const { parent } = result;

        const found = parent?.elements?.filter(
          (e) => e.id !== item.id,
        )
          .find((e) => e.name.trim().toLowerCase() === item.name.trim().toLowerCase());

        if (found) {
          return this.$t('message_type_name_exist');
        }
      }
    }

    return true;
  }
}
