
import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/shared/Loading.vue';
import FailedMessage from '@/components/shared/FailedMessage.vue';
import APIList from '@/components/apis/APIList.vue';
import { GET_LOADING } from '@/store/types';
import { Getter } from 'vuex-class';
import { namespaces } from '@/scripts/namespaces';

@Component({
  components: {
    Loading,
    FailedMessage,
    APIList,
  },
})
export default class API extends Vue {
  // Getters
  @Getter(GET_LOADING, { namespace: namespaces.API }) getLoading: any;

  // Data
  private failedToLoad: string = '';
}
